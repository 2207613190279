import { ChevronDown, ChevronRight, FolderOpen, Loader } from 'lucide-react';
import React from 'react';
import { BucketFolder } from '../types';

interface FolderRowProps {
    folder: BucketFolder;
    level: number;
    currentPath: string;
    isExpanded: boolean;
    isProtected: boolean;
    selectedItems: { [key: string]: boolean };
    toggleFolder: (folderPath: string) => void;
    handleCheckboxChange: (item: string) => void;
    hasContents: boolean;
    getLevelColor: (level: number) => string;
}
const FolderRow: React.FC<FolderRowProps> = ({
    folder,
    level,
    currentPath,
    isExpanded,
    isProtected,
    selectedItems,
    toggleFolder,
    handleCheckboxChange,
    hasContents,
    getLevelColor
}) => (
    <tr className={`folder hover:bg-opacity-80 ${getLevelColor(level)}`}>
        <td className="py-2 px-4">
            <span className={`folder-item ml-${Math.min(level * 4, 40)} flex items-center`}>
                <span 
                    className={`mr-2 ${hasContents ? 'cursor-pointer' : 'cursor-default opacity-50'}`} 
                    onClick={() => hasContents && toggleFolder(currentPath)}
                >
                    {isExpanded ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
                </span>
                <FolderOpen size={16} className="mr-2 text-blue-500" />
                <span className="font-medium flex items-center">
                    {`${folder.name}`}
                    {folder.name === 'root' && (
                        <Loader size={16} className="ml-2 animate-spin text-blue-500" />
                    )}
                </span>
            </span>
        </td>
        <td className="py-2 px-4 text-gray-600">Folder</td>
        <td className="py-2 px-4">
            <div className="flex justify-end">
                {!isProtected && (
                <input
                type="checkbox"
                className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                checked={!!selectedItems[currentPath]}
                onChange={() => handleCheckboxChange(currentPath)}
                aria-label={`Select folder ${folder.name}`}
            />
            )}
            </div>
        </td>
    </tr>
);

export default FolderRow;

