// https://lucide.dev/icons/ for icons
import {
    Archive,
    Binary,
    FileCode,
    File as FileIcon,
    FileImage,
    FileSpreadsheet,
    FileText,
    Film,
    Logs,
    Music
} from 'lucide-react';
import React, { useMemo } from 'react';

interface FileRowProps {
    fileName: string;
    level: number;
    currentPath: string;
    isProtected: boolean;
    selectedItems: { [key: string]: boolean };
    handleCheckboxChange: (item: string) => void;
    getLevelColor: (level: number) => string;
}

const FileRow: React.FC<FileRowProps> = ({
    fileName,
    level,
    currentPath,
    isProtected,
    selectedItems,
    handleCheckboxChange,
    getLevelColor
}) => { 
    const fileExtension = fileName.split('.').pop()?.toLowerCase();

    const getFileIcon = useMemo(() => {
        return (extension: string) => {
            switch (extension) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                case 'gif':
                case 'tif':
                case 'tiff':
                case 'bmp':
                case 'svg':
                    return <FileImage size={16} className="mr-2 text-blue-500" />;
                case 'bin':
                    return <Binary size={16} className="mr-2 text-blue-500" />;
                case 'log':
                    return <Logs size={16} className="mr-2 text-blue-500" />;
                case 'txt':
                case 'md':
                case 'rtf':
                    return <FileText size={16} className="mr-2 text-green-500" />;
                case 'js':
                case 'ts':
                case 'py':
                case 'java':
                case 'cpp':
                case 'html':
                case 'css':
                    return <FileCode size={16} className="mr-2 text-yellow-500" />;
                case 'xls':
                case 'xlsx':
                case 'csv':
                    return <FileSpreadsheet size={16} className="mr-2 text-green-600" />;
                case 'mp4':
                case 'avi':
                case 'mov':
                    return <Film size={16} className="mr-2 text-purple-500" />;
                case 'mp3':
                case 'wav':
                case 'ogg':
                    return <Music size={16} className="mr-2 text-pink-500" />;
                case 'zip':
                case 'rar':
                case '7z':
                    return <Archive size={16} className="mr-2 text-orange-500" />;
                default:
                    return <FileIcon size={16} className="mr-2 text-gray-500" />;
            }
        };
    }, []);

    return (
        <tr className={`file hover:bg-opacity-80 ${getLevelColor(level)}`}>
            <td className="py-2 px-4">
                <span className={`file-item ml-${Math.min((level + 1) * 4, 44)} flex items-center`}>
                    {getFileIcon(fileExtension || '')}
                    <span className="text-gray-700">{fileName}</span>
                </span>
            </td>
            <td className="py-2 px-4 text-gray-600">File</td>
            <td className="py-2 px-4">
                <div className="flex justify-end">
                    {!isProtected && (
                    <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                    checked={!!selectedItems[`${currentPath}/${fileName}`]}
                    onChange={() => handleCheckboxChange(`${currentPath}/${fileName}`)}
                    aria-label={`Select file ${fileName}`}
                    />
                    )}
                </div>
            </td>
        </tr>
    );
}

export default FileRow;