import { useCallback } from 'react';

export const useGetLevelColor = () => {
  return useCallback((level: number) => {
    const colors = [
      'bg-white',
      'bg-blue-50',
      'bg-green-50',
      'bg-yellow-50',
      'bg-red-50',
      'bg-purple-50',
      'bg-gray-50',
      'bg-teal-50',
      'bg-cyan-50',
    ];
    return colors[level % colors.length];
  }, []);
};