import React, { useEffect, useState } from 'react';
import PaddockBucket from './components/PaddockBucket';
import { API_BASE_URL, BUCKET_CONTENTS_API, DELETE_FOLDERS_API } from './constants';
import { BucketFolder } from './types';

const App: React.FC = () => {
  const [existingFolders, setExistingFolders] = useState<BucketFolder>({ name: 'root', files: [], subFolders: [] });
  const [expandedFolders, setExpandedFolders] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    fetchExistingFolders();
  }, []);

  const fetchExistingFolders = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}${BUCKET_CONTENTS_API}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setExistingFolders(data);
    } catch (error) {
      console.error('Error fetching bucket contents:', error);
    }
  };

  const handleDelete = async (folderPaths: string[]) => {
    console.log(`Deleting folders: ${JSON.stringify(folderPaths)}`);
    try {
      const response = await fetch(`${API_BASE_URL}${DELETE_FOLDERS_API}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ paths: folderPaths })
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      console.log('Folders deleted successfully');
      await fetchExistingFolders(); // Refresh the folder structure after deletion
    } catch (error) {
      console.error('Error deleting folders:', error);
    }
  };

  const toggleFolder = (folderPath: string) => {
    setExpandedFolders(prev => ({
      ...prev,
      [folderPath]: !prev[folderPath]
    }));
  };

  return (
    <div className="app">
      <PaddockBucket
        existingFolders={existingFolders}
        onDelete={handleDelete}
        expandedFolders={expandedFolders}
        toggleFolder={toggleFolder}
      />
    </div>
  );
};

export default App;