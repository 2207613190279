import React, { useCallback, useState } from 'react';
import { PROTECTED_FOLDERS } from '../constants';
import { BucketFolder } from '../types';
import { useGetLevelColor } from '../utils/colorUtils';
import FolderStructure from './FolderStructure';
import Header from './Header';

interface PaddockBucketProps {
  existingFolders: BucketFolder;
  onDelete: (items: string[]) => Promise<void>;
  expandedFolders: { [key: string]: boolean };
  toggleFolder: (folderPath: string) => void;
}

const PaddockBucket: React.FC<PaddockBucketProps> = ({ 
  existingFolders, 
  onDelete, 
  expandedFolders, 
  toggleFolder 
}) => {
  const [selectedItems, setSelectedItems] = useState<{ [key: string]: boolean }>({});
  const getLevelColor = useGetLevelColor();

  const handleCheckboxChange = useCallback((item: string) => {
    setSelectedItems(prev => ({ ...prev, [item]: !prev[item] }));
  }, []);

  const isProtectedPath = useCallback((path: string) => {
    const protectedFolders = PROTECTED_FOLDERS;
    return protectedFolders.some(folder => path.toLowerCase().includes(folder));
  }, []);

  return (
    <div className="min-h-screen bg-gray-100">
      <Header />
      <div className="container mx-auto px-4 py-8">
        <main className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50 border-b border-gray-200">
                <tr className="text-gray-700 text-sm font-medium">
                  <th className="py-3 px-6 text-left">Name</th>
                  <th className="py-3 px-6 text-left">Type</th>
                  <th className="py-3 px-6">
                    <div className="flex justify-end items-center">
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                <FolderStructure
                  folder={existingFolders}
                  level={0}
                  onDelete={onDelete}
                  selectedItems={selectedItems}
                  handleCheckboxChange={handleCheckboxChange}
                  expandedFolders={expandedFolders}
                  toggleFolder={toggleFolder}
                  path=""
                  isProtectedPath={isProtectedPath}
                  getLevelColor={getLevelColor}
                />
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </div>
  );
};

export default PaddockBucket;