import React from 'react';
import { BucketFolder } from '../types';
import FileRow from './FileRow';
import FolderRow from './FolderRow';

interface FolderStructureProps {
  folder: BucketFolder;
  level: number;
  onDelete: (selected: string[]) => void;
  selectedItems: { [key: string]: boolean };
  handleCheckboxChange: (item: string) => void;
  expandedFolders: { [key: string]: boolean };
  toggleFolder: (folderPath: string) => void;
  path: string;
  isProtectedPath: (path: string) => boolean;
  getLevelColor: (level: number) => string;

}

const FolderStructure: React.FC<FolderStructureProps> = ({
  folder,
  level,
  onDelete,
  selectedItems,
  handleCheckboxChange,
  expandedFolders,
  toggleFolder,
  path,
  isProtectedPath,
  getLevelColor
}) => {
  if (!folder || typeof folder !== 'object') {
    console.error('Invalid folder object:', folder);
    return null;
  }
  const currentPath = path ? `${path}/${folder.name}` : folder.name;
  const isExpanded = expandedFolders[currentPath] || false;
  const isProtected = isProtectedPath(currentPath);

  const hasContents = (folder.files && folder.files.length > 0) || (folder.subFolders && folder.subFolders.length > 0);

  return (
    <>
      <FolderRow
        folder={folder}
        level={level}
        currentPath={currentPath}
        isExpanded={isExpanded}
        isProtected={isProtected}
        selectedItems={selectedItems}
        toggleFolder={toggleFolder}
        handleCheckboxChange={handleCheckboxChange}
        hasContents={hasContents}
        getLevelColor={getLevelColor}
      />
      {isExpanded && hasContents && (
        <>
          {folder.files && folder.files.map((file, index) => (
            <FileRow
              key={`${index}-${currentPath}/${file.name}`}
              fileName={file.name}
              level={level}
              currentPath={currentPath}
              isProtected={isProtected}
              selectedItems={selectedItems}
              handleCheckboxChange={handleCheckboxChange}
              getLevelColor={getLevelColor}
            />
          ))}
          {folder.subFolders && folder.subFolders.map((subFolder, index) => (
            <FolderStructure
              key={`${index}-${currentPath}/${subFolder.name}`}
              folder={subFolder}
              level={level + 1}
              onDelete={onDelete}
              selectedItems={selectedItems}
              handleCheckboxChange={handleCheckboxChange}
              expandedFolders={expandedFolders}
              toggleFolder={toggleFolder}
              path={currentPath}
              isProtectedPath={isProtectedPath}
              getLevelColor={getLevelColor}
            />
          ))}
        </>
      )}
    </>
  );
};

export default FolderStructure;