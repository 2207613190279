// constants.ts

export const PROTECTED_FOLDERS = ['Caldermeade', 'root'];

export const BUCKET_NAME = 'paddock-images-bucket';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.agritwin.ai';

export const BUCKET_CONTENTS_API = '/api/bucket-contents';

export const DELETE_FOLDERS_API = '/api/delete-folders';

export const UPLOAD_API = '/api/upload';

export const IMAGE_TYPES = ["JPG", "PNG", "GIF", "TIF", "TIFF", "JPEG"];

// Add any other application-wide constants here