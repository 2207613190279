
const Header = () => {
  return (
    <header className="flex items-center px-4 py-2 bg-white shadow-sm">
      <div className="flex-1">
        <img src="/AgriTwinLogoBlackGrass.png" alt="Paddock Bucket" className="h-6" />
      </div>
      <h1 className="flex-1 text-2xl font-bold text-gray-800 text-center">Paddock Bucket</h1>
      <div className="flex-1" /> {/* This empty div balances the layout */}
    </header>
  );
};

export default Header;